@import url("//fonts.googleapis.com/css?family=Arvo:400,700|Raleway:400,300,500|Open+Sans:400,700,900,300");

* {
    font-family: 'Raleway', serif;

    font-weight: 300;

    letter-spacing: 0.4px;

}

/*h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6,*/
/*h1 *, h2 *, h3 *, h4 *, h5 *, h6 *{*/
/*      font-family: 'Arvo',serif;*/
/*      font-weight: 400;*/
/*  }*/

.breadcrumb {
    border-left-color: #3E84C2;
}

.btn-link {
    color: #3E84C2;
}

.btn-link:hover,
.btn-link:focus {
    color: #418d49;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus,
.navbar-inverse .navbar-nav > li > a:focus {
    border-bottom-color: #3E84C2;
}

.navbar-default .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .active > a:focus {
    border-bottom-color: #3E84C2;
}

.navbar-inverse .dropdown-menu > li > a:hover,
.navbar-inverse .dropdown-menu > li > a:focus {
    color: #3E84C2;
}


.nav.open > a,
.nav.open > a:hover,
.nav.open > a:focus {
    background-color: #3E84C2;
    border-color: #3E84C2;
    color: #ffffff;
}

.nav-tabs.nav-stacked > li > a:hover,
.nav-tabs.nav-stacked > li > a:focus,
.nav-tabs.nav-stacked > .active > a,
.nav-tabs.nav-stacked > .active > a:hover,
.nav-tabs.nav-stacked > .active > a:focus {
    border-left-color: #418d49;
    background-color: #3E84C2;
}

.nav-list > .active > a,
.nav-list > .active > a:hover,
.nav-list > .active > a:focus {
    background-color: #3E84C2;
}

.nav .caret {
    border-top-color: #3E84C2;
    border-bottom-color: #3E84C2;
}

.nav a:hover .caret {
    border-top-color: #418d49;
    border-bottom-color: #418d49;
}

a {
    color: #3E84C2;
}

a:hover,
a:focus {
    color: #418d49;
}

.nav a:hover {
    color: #ffffff;
}

a.thumbnail:hover,
a.thumbnail:focus {
    border-color: #3E84C2;
}

.tt-suggestion.tt-is-under-cursor {
    color: #3E84C2;
}

.typeahead:focus {
    border-color: #3E84C2;
}

.form-control:focus {
    border-color: #3E84C2;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    color: #3E84C2;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    color: #3E84C2;
}

.pager li > a:hover,
.pager li > a:focus {
    background-color: #3E84C2;
}

.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > .active > a,
.pagination > .active > span {
    background-color: #3E84C2;
}

.pagination > .active > a,
.pagination > .active > span {
    background-color: #3E84C2;
}

a.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
    color: #3E84C2;
}

blockquote {
    border-left-color: #3E84C2;
}

blockquote.pull-right {
    border-right-color: #3E84C2;
}

.text-primary {
    color: #3E84C2;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    background-color: #3E84C2;
}

a.list-group-item.active {
    background-color: #3E84C2;
    border-color: #3E84C2;
}

a.list-group-item.active .list-group-item-text {
    color: #eff7f0;
}

.btn {
    border-radius: 3px;
}

.btn:hover {
    box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.13);
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
    background-color: #3E84C2;
}

.panel-primary .panel-heading {
    background-color: #3E84C2;
    border-color: #3E84C2;
}

.jumbotron {
    position: relative;
    background: url(./images/VirtualFactoryBanner.png) no-repeat center center;
    background-size: cover;
    color: #ffffff;
    align-content: center;
    padding: 10px;
}

.jumbotron::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    z-index: 1;
}

.jumbotron * {
    position: relative;
    z-index: 2;
}

#about {
    padding: 5px 0;
}

h2 {
    font-size: 2rem;
    font-weight: bold;
}

h3 {
    font-size: 1.5rem;
    font-weight: bold;
}

p {
    font-size: 1.25rem;
    line-height: 1.5;
}

section {
    padding-left: 20px;
}

#services img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}

.jumbotron img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.jumbotron h1 {
    font-size: 40pt;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    text-align: center;
}

.jumbotron h5 {
    text-align: center;
    font-weight: normal;
    font-size: 1.25rem; /* Adjust the size as needed */
    margin-top: 10px; /* Adjust the spacing as needed */
}

.jumbotron .intro .row-top div.ccont {

}

.row-bottom {
    margin-top: 20px;
    margin-bottom: 20px;
}

.jumbotron div.ccont {
    background: #fff;
    min-height: 150px;
}

.jumbotron .row-top div.ccont {
    -webkit-box-shadow: 0px 1px 2px rgba(100, 100, 100, 0.75);
    -moz-box-shadow: 0px 1px 2px rgba(100, 100, 100, 0.75);
    box-shadow: 0px 1px 2px rgba(100, 100, 100, 0.75);

}

.jumbotron .row-bottom div.ccont {
    -webkit-box-shadow: -1px -1px 2px rgba(100, 100, 100, 0.75);
    -moz-box-shadow: -1px -1px 2px rgba(100, 100, 100, 0.75);
    box-shadow: -1px -1px 2px rgba(100, 100, 100, 0.75);

}

.jumbotron p {
    padding: 20px 0;
}

.jumbotron .media {
    margin: 50px 0;
}

.jumbotron .media-body h1 {
    margin-top: 100px;
}

section.slider {
    color: #ffffff;
    background: #2D6D7B;
    margin: 40px 0;
    padding: 40px 0;
}

section.slider h2 {
    margin-bottom: 20px;
}

section.slider .col-md-8 {
    padding-left: 30px;
}

.big-list {
    font-size: 25px;
    font-weight: 300;
    margin-top: 40px;
    line-height: 2;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}


.inner-page {
    margin-bottom: 30px;
    padding-bottom: 30px;
}

img {
    max-width: 100%;
}

.pricing-intro {
    margin-bottom: 70px;
}

section.gray {

    padding: 30px 0;
    background: #eeeeee;
}

section.gray h3 {
    margin: 0;
    font-size: 22pt;
}

section.about {
    background: url(images/tweed.png);
    min-height: 400px;
    position: relative;
}

section.about .dmask {
    width: 100%;
    height: 100%;
    min-height: 400px;
    /*background: rgba(100, 0, 0, 0.42);*/
    color: #ffffff;
    padding: 40px 0;
}

.our-clients .testimonial p, .our-clients .testimonials-arrow {
    border: medium none;
}

.clients-title h3,
.testimonials h3 {
    margin-bottom: 10px !important;
}

.testimonials-arrow {
    background-color: #FAFAFA;
    border-bottom: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-top: 0 none;
    content: "";
    display: block;
    height: 14px;
    margin-bottom: 10px;
    margin-left: 45px;
    margin-top: -7px;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 14px;
}

.client-logo .carousel-controls {
    width: 80px;
    text-align: right;
}

.testimonial p {
    background: #ffffff;
    border: 1px solid #DDDDDD;
    font-weight: 500;
    margin: 0;
    padding: 27px 25px;
    color: #222222;
    border-radius: 3px;
    line-height: 1.7;
}

.our-clients .testimonial-author-info {
    color: #DDDDDD;
}

.testimonial-author-info {
    color: #666666;
    padding: 10px 0 0 90px;
}

.testimonial-image {
    float: left;
    height: 70px;
    position: relative;
    width: 70px;
}

.our-clients .testimonial-image img {
    border: medium none;
    padding: 0;
}

.testimonial-image img {
    border: 1px solid #DDDDDD;
    border-radius: 50px 50px 50px 50px;
    height: 70px !important;
    padding: 4px;
    width: 70px !important;
}

.testimonial .author {
    margin: 0 0 0 19px;
}

.client-carousel img {
    border-radius: 3px;
}

.dmask .title span {
    font-weight: 900;
}

.dmask .title {
    margin-bottom: 30px;
    font-weight: 300;
    font-size: 17pt;
    letter-spacing: 1px;
}

section#portfolio {
    padding-bottom: 70px;
    position: relative;
    min-height: 600px;
}

section.pricing {
    background: #2c6393;
    padding: 30px 0 50px 0;
}

div.white, div.white * {
    color: #ffffff !important;
}

div.pricing-circle {
    border-radius: 50%;
    height: 120px;
    width: 120px;
    line-height: 120px;
    text-align: center;
    margin: 10px auto 20px;
    font-size: 16pt;
    font-weight: 900;
    color: #ffffff;
}

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

body {
    padding-top: 60px;
}

.pricing-table .panel {
    background: #eeeeee;
    border: 0;
}

div.pricing-table .panel .panel-heading h3 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    font-family: Montserrat, serif;
    font-size: 14pt;
    font-weight: 900;
}

.pricing-intro h2 {
    margin-bottom: 20px;
}

.pricing-table .list-group-item {
    padding: 15px 20px;
    font-size: 10pt;
}

.pricing-table .list-group-item i {
    margin-right: 10px;
}

.client-logo .carousel-controls {
    margin-top: -30px;
}

.client-logo .carousel-controls a {
    color: #ffffff;
    padding: 0 8px 2px 8px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.4);
}

.client-logo .carousel-controls a:first-child {
    margin-right: 2px;
}

.testimonial-author-info a {
    color: #ffffff;
    font-weight: 900;
    display: block;
}

a.list-group-item.active {
    background-color: #389968;
    border-color: #389968;
}

a.list-group-item .list-group-item-heading {
    margin-bottom: 10px;
    font-weight: 400;
}


.features {
    padding-top: 30px;
}

.features p {
    margin: 30px 0;
}

.features .col-lg-4:first-child .icon-stack-base {
    color: #52AD81;
}

.features .col-lg-4:nth-child(2) .icon-stack-base {
    color: #CD5A48;
}

.features .col-lg-4:last-child .icon-stack-base {
    color: #51A9D6;
}

.label {
    font-weight: 300;
}

div.feature-box {
    clear: both;
    margin-top: 30px;
}

div.feature-box h4 {
    margin-bottom: 3px;
}

div.feature-box div.feature-box-icon {
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    background: #ffffff;
    border-radius: 6px;
    color: #2D6D7B;
    display: inline-block;
    float: left;
    height: 45px;
    line-height: 45px;
    margin-right: 10px;
    position: relative;
    text-align: center;
    top: 0px;
    width: 45px;
}

div.feature-box div.feature-box-info {
    padding-left: 50px;
}

div.feature-box div.feature-box-icon i.image-icon {
    position: relative;
    top: 5px;
}

div.feature-box div.feature-box-icon i.image-icon.small {
    top: 2px;
}

footer div.footer-ribbon:before {
    border-right-color: #005580;
}

footer div.footer-ribbon:before {
    border-right: 10px solid #005580;
    border-top: 16px solid rgba(0, 0, 0, 0);
    content: "";
    display: block;
    height: 0;
    left: -10px;
    position: absolute;
    top: 0;
    width: 7px;
}

footer div.container div.row > div {
    margin-bottom: 25px;
}

footer div.footer-ribbon {
    background: #0088CC;
    margin: -100px 0 0 30px;
    padding: 10px 20px 6px;
    position: absolute;
    color: #ffffff;
    font-weight: 700;
}

footer div.footer-ribbon span {
    color: #ffffff;
    font-weight: 700;
}

footer {
    background: #121214;
    border-top: 4px solid #1F1F1F;
    font-size: 0.9em;
    padding: 80px 0 0;
    position: relative;
}

img {
    max-width: 100%;
}

section.contact {
    background: #f5f5f5 url(images/map-bottom.png);
    min-height: 400px;
    background-size: auto 100%;
    padding-top: 30px;
}

section.contact ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

section.contact li {
    line-height: 2;
}

footer * {
    color: #ffffff;
}

.footer-copyright {
    margin-top: -40px;
}

#footer-menu {
    text-align: right;
}

#footer-menu li {
    display: inline;
    list-style: none;
    margin-left: 10px;
}

.title span {
    font-weight: 900;
}

.navbar-color {
    background: rgba(0, 0, 0, 0.7);
}

.navbar-nav {
    margin-top: 6px;
    float: right;
}

.navbar-nav li a {
    color: #ffffff;
    font-weight: 300;
    text-transform: lowercase;
    padding: 10px 20px;
    font-size: 13pt;
    border-radius: 2px;
    margin-left: 3px;
}

.navbar-nav li a:focus,
.navbar-nav li a:active,
.navbar-nav li.active a,
.navbar-nav li a:hover {
    background: rgba(255, 255, 255, 0.2);
    outline: none !important;
}

.hexicon {
    background: url("images/hex.png");
    width: 132px;
    height: 210px;
    position: relative;
    margin: 0 auto;
}

.inicon {
    position: absolute;
    width: 100%;
    height: 100%;
}

.inicon.retina {
    background: url("images/retina.png") no-repeat center 40px;
    background-size: 80px auto;
}

.inicon.support {
    background: url("images/support.png") no-repeat center 40px;
    background-size: 80px auto;
}

.inicon.speed {
    background: url("images/speed.png") no-repeat center 40px;
    background-size: 80px auto;
}

.inicon.social {
    background: url("images/social.png") no-repeat center 40px;
    background-size: 80px auto;
}

.inicon.shopping {
    background: url("images/shopping.png") no-repeat center 40px;
    background-size: 80px auto;
}

.inicon.cloud {
    background: url("images/cloud.png") no-repeat center 40px;
    background-size: 80px auto;
}

p.lead {
    font-family: 'Arvo' !important;
}

.up-triangle, .down-triangle {
    float: left;
    margin-left: -100px;
}

.up-triangle:first-child {
    margin-left: 20px;
}

@media (max-width: 980px) {

    .up-triangle, .down-triangle {
        margin-bottom: 20px;
    }

    .down-triangle:nth-child(5) {
        margin-left: 20px;
    }

    .icon-reorder {
        color: #ffffff;
    }

    .navbar-nav {
        clear: both !important;
        float: none;
        padding: 10px;
    }

    .navbar-header {
        margin-left: 50% !important;
    }

    .navbar-brand {
        margin-left: -80px !important;
    }

}

@media (max-width: 360px) {
    .up-triangle, .down-triangle {
        clear: both;
        margin-left: 50px !important;

    }

    .icon-reorder {
        color: #ffffff;
    }

    .navbar-nav {
        clear: both !important;
        float: none;
        padding: 10px;
    }
}


.up-triangle {
    width: 0;
    height: 0;
    border-bottom: 200px solid #ffffff;
    border-left: 122px solid transparent;
    border-right: 122px solid transparent;
    position: relative;

}

.down-triangle {
    width: 0;
    height: 0;
    border-top: 200px solid #ffffff;
    border-left: 122px solid transparent;
    border-right: 122px solid transparent;
    position: relative;

}

.up-triangle div.info {
    position: absolute;
    bottom: -170px;
    color: #ffffff;
    margin-left: -100px;
    width: 200px;
    text-align: center;
    font-size: 14pt;
    font-family: 'Arvo';
}

.down-triangle div.info {
    position: absolute;
    top: -170px;
    color: #ffffff;
    margin-left: -100px;
    width: 200px;
    text-align: center;
    font-family: 'Arvo';
    font-size: 14pt;
}

.triangles {
    margin-bottom: 40px;
}

.triangles .icon {
    font-size: 22pt;
    color: #ffffff;
    line-height: 60px;
    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
    -o-transition: all 500ms ease-out;
    transition: all 500ms ease-out;
}

.down-triangle .icon {
    padding-top: 15px;
    line-height: 60px;

}

.up-triangle:hover .icon,
.down-triangle:hover .icon {
    color: rgba(52, 118, 135, 0.9);
}

.portfolio-block {
    position: relative;
    margin-bottom: 30px;
    border-radius: 3px;
}

.portfolio-block .portfolio-caption {
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: #ffffff;
    top: 0;
    border: 20px solid rgba(255, 255, 255, 0.3);
    opacity: 0;
}

.portfolio-block .portfolio-caption h3 {
    font-size: 15pt;
    margin-top: 30px;
    -webkit-animation-name: zoomOut;
    animation-name: zoomOut;
}

.portfolio-block .portfolio-caption .btn-sm {
    padding-top: 11px;
}

.portfolio-block {
    overflow: hidden;
}

.portfolio-block .portfolio-caption {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
}

.portfolio-block .portfolio-caption .btn-skype {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft;
}

.portfolio-block .portfolio-caption .btn-stack-overflow {
    -webkit-animation-name: fadeOurRight;
    animation-name: fadeOurRight;
}

.portfolio-block:hover .portfolio-caption {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

.portfolio-block:hover .portfolio-caption h3.animated {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
    -webkit-animation-delay: 200ms;
    animation-delay: 200ms;
}

.portfolio-block:hover .portfolio-caption .btn-skype {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-delay: 200ms;
    animation-delay: 200ms;
}

.portfolio-block:hover .portfolio-caption .btn-stack-overflow {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
    -webkit-animation-delay: 200ms;
    animation-delay: 200ms;
}

.btn {
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.2) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.2) !important;
}
