/* Custom Styles for Enhancements or Specific Design Requirements */

  .your-component-container .sticky-header {
    position: sticky !important;
    top: 0;
    background-color: white; /* You can adjust the background color as needed */
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
  .dropdown-box,
  .input-box {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dropdown-box select,
  .input-box input {
    width: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .submit-button {
    background-color: #007BFF;
    color: #fff;
    display: flex; /* Make the button appear on the next line */
    font-size: 24px; /* Increase font size for approximately 3 times bigger */
    padding: 20px; /* Adjust padding for a larger button */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.add-row-button-container {
  display: flex;          /* Use flexbox to align items */
  justify-content: center; /* Center the button horizontally */
  align-items: center;    /* Center the button vertically if needed */
  margin-top: 20px;       /* Add some spacing above the button if needed */
}

.add-row-button {
  background-color: #4CAF50;
  color: #fff;
  display: flex;
  font-size: 24px;
  padding: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
  .submit-button:hover {
    background-color: #0056b3;
  }

  .Selectors {
    margin-bottom: 10px; /* Add some space between Selectors and LabTable */
  }

  .selectors-box {
    display: flex; 
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  /* styles.css (or your component's stylesheet) */
.chart-container {
  border: 2px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden; /* Hide overflowing elements within the box */
  margin: 20px; /* Add some margin around the chart container */
}

/* Styling for boxes like dropdown and input that need a consistent look */
.dropdown-box,
.input-box {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: .25rem; /* Align with Bootstrap's default border-radius */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: .5rem; /* Adjusted for consistency with Bootstrap's spacing */
}

/* Custom tooltip styling */
.tooltip {
  font-family: Arial, sans-serif;
  font-size: .875rem; /* Align with Bootstrap's font sizing */
  background-color: white;
  border: 1px solid #ddd;
  border-radius: .25rem;
  padding: .5rem;
  position: absolute;
  z-index: 1070; /* Ensures tooltips are above most other elements */
}

/* Modal content customization */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* Centering might need adjustment based on your modal trigger and layout */
  padding: 20px;
  border: 1px solid #888;
  width: 60%; /* Responsive width adjustments may be needed for smaller screens */
  border-radius: .3rem; /* Consistency with Bootstrap's border-radius */
}

.close {
  float: right;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: #f00;
} 
  
  
.chart-container {
  width: 100%; /* Set the width to 100% for responsiveness */
  height: 100%; /* Set the height to 100% for responsiveness */
  position: relative; /* Make it relative to its container */
  overflow: hidden; /* Hide overflow to prevent scrollbars */
}

/* Style for the SVG element inside the container */
.chart-container svg {
  width: 100%; /* Set SVG width to 100% to fill the container */
  height: 100%; /* Set SVG height to 100% to fill the container */
}

.narrow-table {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-title{
  display: flex;
  justify-content: center;
  align-items: center;
}

.signout-button{
  background-color: #ff0000;
    color: #fff;
    display: block; /* Make the button appear on the next line */
    font-size: 24px; /* Increase font size for approximately 3 times bigger */
    padding: 20px; /* Adjust padding for a larger button */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 150%;
}

.sign-button{
     background-color: #007BFF;
    color: #fff;
    display:flex; /* Make the button appear on the next line */
    font-size: 14px; /* Increase font size for approximately 3 times bigger */
    padding: 10px; /* Adjust padding for a larger button */
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.bold-header{
  font-weight: bold;
}

.narrow-cell{
  white-space: nowrap;
}

.table-container {
  height: 300px; /* Set a specific height for the container */
  overflow-y: auto; /* Add a vertical scrollbar when needed */
}

.hourly-table {
  position: sticky;
  top: 0;
  font-weight: bold;
  height: 650px; /* Set a specific height for the container */
  overflow-y: auto; /* Add a vertical scrollbar when needed */
  overflow-x: auto;
  background-color: #fff; /* Ensure it's visible on top of content */
}

.hourlylosses-table {
  position: sticky;
  top: 0;
  font-weight: bold;
  height: 300px; /* Set a specific height for the container */
  overflow-y: auto; /* Add a vertical scrollbar when needed */
  overflow-x: auto;
  background-color: #fff; /* Ensure it's visible on top of content */
}

.sticky-row {
    position: sticky !important;
    top:0;
    background-color: white; /* You can adjust the background color as needed */
    z-index: 999;
}

.lab-sheet-table {
  display: flex;
  justify-content: center;
  align-items: center;
}

.detailed-view {

}

.averages {

}

.margin-inc {

}

.plot-button {
  background-color: #007BFF;
  color: white;
  border-radius: .25rem; /* Consistency with Bootstrap's border-radius */
  cursor: pointer;
  display:flex; /* Make the button appear on the next line */
}

/* Table container for scrollable tables */
.table-container, .hourly-table, .hourlylosses-table {
  overflow-y: auto;
  /* Consider using .table-responsive in the container div instead */
}

.selectors-box {
  display: flex; 
  flex-direction: row;
}

.box-container {
  border: 10px solid blue; /* Add a border to create a box */
  padding: 20px; /* Add padding for spacing inside the box */
  width: 800px; /* Set the desired width for the box */
  margin: 0 auto; /* Center the box horizontally on the page */
}

.parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.plot-box {
  margin-bottom: 20px;
}

.table-box {
  margin-top: 20px;
}

.legend-container {
  width: 200px;
  height: 150px; /* Set the height of the scrollable container */
  overflow-y: scroll; /* Enable vertical scrolling */
  border: 1px solid #ccc; /* Optional: Add a border for better visibility */
}

.data-entry-table-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: auto;
  overflow-x: auto; /* Add horizontal scrolling if needed */
}

.data-entry-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Ensure equal distribution of column space */
}

.data-entry-table th, 
.data-entry-table td {
  padding: 10px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  overflow: hidden; /* Ensure content doesn't overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  white-space: nowrap; /* Prevent text from wrapping */
}

.data-entry-table th {
  background-color: #4CAF50;
  color: white;
}

.data-entry-input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.bulk-fill-label {
  text-align: center;
  background-color: #f0f0f0;
  font-weight: bold;
  padding: 10px;
  border-bottom: 2px solid #ccc;
}

.bulk-fill-row {
  background-color: #e8f5e9; /* Light green background to distinguish it */
}

.bulk-fill-row td {
  padding: 10px 15px;
}

.data-entry-input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  transition: border-color 0.2s, box-shadow 0.2s;
  outline: none; /* Removes default browser outline */
}

.data-entry-input:focus {
  border-color: #4CAF50; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5); /* Optional: Add a subtle shadow */
}

.cell-focus {
  outline: 2px solid #3f51b5; /* A solid border to indicate focus */
  background-color: rgba(63, 81, 181, 0.1); /* Optional: lighter background for the focused cell */
  border-color: #4CAF50; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5); /* Optional: Add a subtle shadow */
}


